<template>
  <el-dialog
    title="添加商品"
    :visible.sync="dialogVisible"
    center
    width="900px"
    append-to-body
    :before-close="handleClose">
    <el-table
      ref="multipleTable"
      :max-height="maxHeight"
      :data="datas"
      border
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="icon"
        label="图片"
        width="100">
        <template slot-scope="scope">
          <el-image :src="scope.row.icon" style="width: 40px; height: 40px;"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="产品名"
        width="170">
      </el-table-column>
      <el-table-column
        type="sort"
        label="限时(分)"
        width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.task_will_finish"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="80">
      </el-table-column>
      <el-table-column
        prop="bonus"
        label="红包"
        width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.is_bonus == 1" style="color: red">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="desc"
        label="说明">
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="danger" @click="handleSubmit">
        确定添加
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { thumb } from "@/utils/utils";
import { requestHttp } from "@/api/main";

export default {
  name: "DialogAllProduct",
  components: { },
  data() {
    return {
      maxHeight: 500,
      dialogVisible: false,
      postForm: {
        level_id: '',
        page: 1,
        total: 0,
        limit: 20
      },
      datas: [],
      selectionData: []
    }
  },
  methods: {
    thumb,
    toggle(levelId) {
      this.postForm.level_id = levelId
      this.selectionData = []
      this.fetchData()
      this.dialogVisible = true
    },
    handleSelectionChange(e) {
      this.selectionData = e
      console.error(e)
    },
    handleClose() {
      this.dialogVisible = false
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_all_product_list', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.products
        }
      })
    },
    handleSubmit() {
      const that = this
      let id = []
      let willFinish = []
      if (this.selectionData.length == 0) {
        that.$message.error('至少选中一行')
        return
      }
      this.selectionData.forEach(item => {
        id.push(item.id)
        willFinish.push(item.task_will_finish)
      })
      requestHttp('agent/save_user_task_level_products', {
        level_id: this.postForm.level_id,
        products: id.join(','),
        task_will_finish: willFinish.join(',')
      }).then((res) => {
        if (res.code == 200) {
          that.$message.success(res.message)
          that.$emit('change')
          that.handleClose()
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <el-dialog
    title="送出彩金"
    :visible.sync="dialogVisible"
    center
    width="800px"
    :before-close="handleClose">

    <el-form>
      <el-form-item label="彩金">
        <el-input v-model="setSendSettingForm.default" style="width: 100px; margin-right: 10px" placeholder="金额"/>
        <el-button type="danger" @click="handleSendGift">送出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="datas"
      border>
      <el-table-column
        prop="action_time"
        label="时间"
        width="100">
      </el-table-column>
      <el-table-column
        prop="user_id"
        label="UserID"
        width="120">
      </el-table-column>
      <el-table-column
        prop="money"
        label="金额"
        width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.method_type == 1" class="green">+{{scope.row.money}}</span>
          <span v-else class="red">{{scope.row.money}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="note"
        label="备注">
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";

export default {
  name: "DialogOrderLog",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      messageId: '',
      datas: [],
      activeTabsName: 'first',
      setSendSettingForm: {
        userId: '',
        messageId: '',
        default: 1000,
        default_set: false
      }
    }
  },
  methods: {
    toggle(userId, messageId) {
      if (userId) {
        this.userId = userId
        this.messageId = messageId
        this.setSendSettingForm.userId = userId
        this.setSendSettingForm.messageId = messageId
        this.dialogVisible = true
        this.fetchData()
      }
    },
    handleClose() {
      this.dialogVisible = false
    },

    handleTabsClick(e) {
      if (e.name == 'second') {
        this.fetchData()
      }
    },

    handleSetDefaultGift() {

    },
    fetchData() {
      const that = this
      that.datas = [];
      requestHttp('agent/get_send_gift_log', {
        'user_id': that.userId,
        limit: 10
      }).then((res) => {
        if (res.code == 200) {
          that.datas = res.data.data
          that.setSendSettingForm.default = res.data.default_gift
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleSendGift() {
      // setSendSettingForm.default
      const that = this
      this.$confirm('确认送出彩金？',  '提示').then(function () {
        requestHttp('agent/send_gift', {
          'user_id': that.userId,
          'gift': that.setSendSettingForm.default,
          'message_id': that.messageId,
          'default': 1
        }).then((res) => {
          if (res.code == 200) {
            that.$emit('confirm', {
              'gift': that.setSendSettingForm.default,
              'message_id': that.messageId
            })
            that.$message.success(res.message)
            that.handleClose()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
  }
}
</script>

<style scoped>
  .green {
    color: #35b65a !important;
  }
  .red {
    color: #ff494a !important;
  }
</style>

<template>
  <el-dialog
    title="任务等级"
    :visible.sync="dialogVisible"
    center
    width="1000px"
    :before-close="handleClose">
    <div style="text-align: right; padding-bottom: 5px">
      <el-button size="mini" type="primary" @click="fetchData">刷 新</el-button>
      <el-button size="mini" type="danger" @click="showEdit">新 增</el-button>
      <el-button size="mini" type="danger" @click="unfreezeTask">一键解冻</el-button>
    </div>
    <el-table
      height="500"
      :data="datas"
      border>
      <el-table-column
        prop="icon"
        label="ICON"
        width="60">
        <template slot-scope="scope">
          <el-image :src="thumb(scope.row.icon)" style="width: 30px; height: 30px"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="名称"
        align="center"
      >
        <template slot-scope="scope">
          ({{scope.row.level}}) {{scope.row.title}}<br/><span style="color: #aaa; font-size: 10px">{{scope.row.des}}</span>
          <br/><span style="color: red; font-size: 10px">限时 {{scope.row.task_will_finish}} 分</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="need_task"
        label="商品/完成/几轮"
        align="center"
      >
        <template slot-scope="scope">
          {{scope.row.product_count}} / {{scope.row.product_finished_count}} / {{scope.row.rank}}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_loop"
        label="循环"
        align="center"
        width="80"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_loop == 1" style="color: red">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="auto_upgrade"
        label="自动升级"
        align="center"
        width="80"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.auto_upgrade == 1" style="color: red">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_finish"
        label="完成"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_current == 1" style="color: red">当前</span>
          <span v-else-if="scope.row.is_finish == 1" style="color: green">是</span>
          <span v-else>否</span><br>
          <span v-if="scope.row.last_task_time != ''">{{scope.row.last_task_time}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        width="80"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">启用</span>
          <span v-else style="color: red">禁用</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="earning"
        width="140"
        label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" size="mini" @click="showEdit(scope.row)">修改</el-button>
            <el-button type="warning" size="mini" @click="showProductEdit(scope.row)">商品</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <br/>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
      <el-button type="danger" @click="fetchData">刷新</el-button>
    </div>
    <dialog-task-level-edit ref="dialogTaskLevelEdit" @change="fetchData"></dialog-task-level-edit>
    <dialog-task-level-product ref="dialogTaskLevelProduct" @change="fetchData"></dialog-task-level-product>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";
import DialogTaskLevelEdit from "@/views/chat/components/dialog-task-level-edit";
import DialogTaskLevelProduct from "@/views/chat/components/dialog-task-level-product";

export default {
  name: "DialogTaskLevel",
  components: {
    DialogTaskLevelEdit, DialogTaskLevelProduct
  },
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      searchForm: {
        user_id: 0
      },
      timer: 0,
      datas: []
    }
  },
  methods: {
    thumb,
    toggle(userId) {
      const that = this
      this.userId = userId
      this.searchForm.user_id = userId
      this.dialogVisible = true
      this.fetchData()
      // if (this.timer > 0) {
      //   clearInterval(this.timer)
      // }
      // this.timer = setInterval(function () {
      //   that.fetchData()
      // }, 5000)
    },
    handleClose() {
      clearInterval(this.timer)
      this.dialogVisible = false
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    fetchData() {
      const that = this
      that.datas = [];
      requestHttp('agent/get_user_task_level', this.searchForm).then((res) => {
        if (res.code == 200) {
          that.datas = res.data
        } else {
          that.$message.error(res.message)
        }
      }).catch((res) => {
        that.$message.error(res.message)
      })
    },
    unfreezeTask() {
      const that = this
      this.$confirm('解除后将立即把冻结金额返还，确定吗？', '提示').then(function () {
        requestHttp('agent/unfreeze_task', {
          'user_id': that.userId
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.fetchData()
          } else {
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    handleSwitchTradeStatus(row, status) {
      const statusArr = {
        3: '盈利',
        4: '亏损',
      }
      const that = this
      this.$confirm('确认设置交易状态为' + statusArr[status] + '？', '提示').then(function () {
        requestHttp('agent/switch_order_trade_status', {
          'id': row.id,
          'trade_status': status
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.fetchData()
          } else {
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    showEdit(row) {
      this.$refs.dialogTaskLevelEdit.toggle(row.id, this.userId)
    },
    showProductEdit(row) {
      this.$refs.dialogTaskLevelProduct.toggle(row.id)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <el-dialog
    title="商品管理"
    :visible.sync="dialogVisible"
    append-to-body
    center
    width="1000px"
    :before-close="handleClose">
    <div style="text-align: right; padding-bottom: 5px">
      <el-button size="mini" type="danger" @click="fetchData">刷 新</el-button>
      <el-button size="mini" type="primary" @click="handleAddProduct">添加商品</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :max-height="maxHeight"
      :data="datas"
      border
      @selection-change="handleSelectionChange">
      <el-table-column
        type="index"
        width="55">
      </el-table-column>
      <el-table-column
        type="sort"
        label="排序"
        width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.sort" @change="handleSort(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="icon"
        label="图片"
        width="60">
        <template slot-scope="scope">
          <el-image :src="scope.row.icon" style="width: 40px; height: 40px;"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="产品名"
        width="170">
        <template slot-scope="scope">
          {{scope.row.title}}
        </template>
      </el-table-column>
      <el-table-column
        type="sort"
        label="限时(分)"
        width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.task_will_finish" @change="handleTaskWillFinishChange(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="80">
      </el-table-column>
      <el-table-column
        prop="bonus"
        label="红包"
        width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.is_bonus == 1" style="color: red">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="领取"
        width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">未领取</span>
          <span v-else style="color: red">
            {{scope.row.picked_time}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="预计完成时间">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">未领取</span>
          <span v-else-if="scope.row.status == 1">
            <el-date-picker style="width: 150px" @change="handleFinishTime(scope.row)" size="mini" type="datetime" format="MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="scope.row.finish_time"></el-date-picker>
          </span>
          <span v-else-if="scope.row.status == 2" style="color: red">
            {{ scope.row.finish_time }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_finished"
        label="状态"
        width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.is_finished == 1" style="color: green">已完成</span>
          <span v-else style="color: red">未完成</span>
          <span v-if="scope.row.is_win == 1" style="color: green">(赢)</span>
          <span v-else-if="scope.row.is_win == 2" style="color: red">(输)</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="method"
        label="操作"
        width="80"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleAddProduct">添加商品</el-button>
      <el-button type="danger" @click="fetchData">刷新商品</el-button>
    </div>
    <dialog-all-product ref="dialogAllProduct" @change="fetchData"></dialog-all-product>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { blobToBase64, coverTime, thumb } from '@/utils/utils'
import DialogAllProduct from "@/views/chat/components/dialog-all-product";

export default {
  name: "DialogTaskLevelProduct",
  components: {
    DialogAllProduct
  },
  data() {
    return {
      dialogVisible: false,
      maxHeight: 500,
      postForm: {
        level_id: '',
        page: 1,
        total: 0,
        limit: 20
      },
      datas: [],
      selectionData: []
    }
  },
  methods: {
    coverTime,
    thumb,
    toggle(levelId) {
      this.postForm.level_id = levelId
      this.selectionData = []
      this.fetchData()
      this.dialogVisible = true
    },
    handleAddProduct() {
      this.$refs.dialogAllProduct.toggle(this.postForm.level_id)
    },
    handleSelectionChange(e) {
      this.selectionData = e
      console.error(e)
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleTaskWillFinishChange(row) {
      const that = this
      requestHttp('agent/save_user_task_level_product_will_finish_time', row).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          that.$message.success(res.message)
        }
      })
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_user_task_level_product', this.postForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.datas = res.data.products
        }
      })
    },
    handleSort(item) {
      const that = this
      requestHttp('agent/sort_user_task_level_products', {
        'id': item.id,
        sort: item.sort
      }).then((res) => {
        if (res.code == 200) {
          that.$message.success(res.message)
          that.fetchData()
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleDelete(id) {
      const that = this
      this.$confirm('删除后不可恢复，确认吗？', '提示').then(function () {
        requestHttp('agent/delete_user_task_level_products', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleFinishTime(row) {
      console.log(row)
      const that = this
      this.$confirm('确认设置为'+ row.finish_time +'？', '提示').then(function () {
        requestHttp('agent/save_user_task_level_product_finish_time', {
          'id': row.id,
          'finish_time': row.finish_time
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleSubmit() {
      const that = this
      let ip = []
      if (this.selectionData.length == 0) {
        that.$message.error('至少选中一行')
        return
      }
      this.selectionData.forEach(item => {
        ip.push(item.id)
      })
      requestHttp('agent/save_user_task_level_products', {
        level_id: this.postForm.level_id,
        products: ip.join(',')
      }).then((res) => {
        if (res.code == 200) {
          that.$message.success(res.message)
          that.handleClose()
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
